.App {
  text-align: center;
}

.Tag-token {
  margin-inline-end: 0px;
  line-height: 30px;
  font-size: 20px;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Alert {
  background: none !important;
}

.snap-to-bottom {
  margin-bottom: 10px;
}
